/**
 * This files holds the global front-end part of the code supporting direct links to anywhere within soda.
 *
 * For the backend and documentation on the architecture see DirectLinkController.php.
 * For route-specific example code see the PHP code in ModulesController@index, and the JS code in modules.blade.php.
 */


/**
 * This is the function that performs the front-end navigation.
 * Takes an object with instructions for how to get to a (possibly nested) modal target context.
 * @param {*} dlData
 */
const navigateToContext = async (dlData) => {

    if (!(dlData && dlData.path)) {
        console.warn("Direct link: navigateToContext was called without valid link path data. Doing nothing.");
        return;
    }

    const steps = Object.keys(dlData.path).length;

    if (!steps) {
        console.log(`Direct link: target was the page; nothing to do.`);
        return;
    }

    showCoverLayer();

    try {
        // Clear the searchbox(es) to make sure the target element will not be filtered out.
        await clearSearchBoxes();

        // Get a handle to the progress bar on the cover layer.
        const $progressBar = $('#coverLayer .progress').progressbar({ max: steps - 1 });
        $progressBar.find('div').addClass('dl-progress-bar');

        console.log(`Direct link: ${steps} steps to target context:`, dlData.target_context);

        for (let i = 0; i < steps; i++) {
            const targetInfo = dlData.path[i];
            let fullSelector;
            const { selector, action, context, targetIdAttr, targetIdVal } = targetInfo;


            console.log(`Step ${i + 1}/${steps}: ${action} (${context})`);

            switch (action) {
                case 'clickLink':
                    fullSelector = getFullSelector(selector, 'a', targetIdAttr, targetIdVal);

                    try {
                        const $link = await waitForElement(fullSelector);
                        if (!$link) {
                            throw new Error('Unexpected error during direct link navigation: link not found.');
                        }
                        console.log('Clicking the link.');
                        $link.click();
                    } catch (err) {
                        throw err;
                    }
                    break;

                case 'findInTable':
                    try {
                        await waitForTableReload(selector);

                        fullSelector = getFullSelector(selector, 'tr', targetIdAttr, targetIdVal);
                        console.log(`Find in table`, targetIdAttr, targetIdVal);
                        const $dataTablesLengthSelectBox = $('.dataTables_length select');

                        const itemsPerPage = $dataTablesLengthSelectBox.val();
                        const targetPageIndex = Math.floor(targetInfo.rowIndex / itemsPerPage);

                        console.log(`Index is ${targetInfo.rowIndex}, there are ${itemsPerPage} rows in each table page, so need to check page with index ${targetPageIndex}`);

                        if (targetPageIndex > 0) {
                            const $paginateButton = await waitForElement(selector + ` .paginate_button a[data-dt-idx="${targetPageIndex}"]`);

                            if ($paginateButton.length) {
                                console.log('Clicking the pagination button', $paginateButton);
                                $paginateButton.click();
                            } else {
                                throw new Error('Unexpected error during direct link navigation: target page of table did not load or does not exist.');
                            }

                            await waitForTableReload(selector);
                        }

                        // Now the target item should be on the page
                        console.log(`Target item expected at '${fullSelector}`);
                        const $targetItem = await waitForElement(fullSelector);
                        if ($targetItem.length) {
                            console.log(`Got the target ${context} (${targetIdVal}) `);

                            if (i === steps - 1) {
                                console.log(`Success - arrived at link target ${context}`);
                                $('.bootbox').scrollTop($($targetItem).offset().top - 20);
                                $targetItem.addClass('dl-highlighted')
                            }
                        } else {
                            throw new Error(`Target ${context} not found`);
                        }
                    } catch (err) {
                        throw err;
                    }


                    break;
            }

            $progressBar.progressbar({ value: i });
        }

        hideCoverLayer();

    } catch (err) {
        // If the auto navigation fails, ensure to hide the cover layer
        console.log('Got an error while trying to navigate a direct link: ', err);
        hideCoverLayer();
    }

};

/**
 * If one or more datatables searchboxes are present on the page, this will
 * clear search filters where present.
 */
const clearSearchBoxes = async () => {
    try {
        const $searchBoxes = await waitForElement('.dataTables_filter input');

        $searchBoxes.each((index, searchBox) => {
            if ($(searchBox).val()) {
                $(searchBox).val('').keyup();
                console.log(`Cleared search box ${index}.`);
            }
        });
    } catch (err) {
        console.log(`No searchboxes were found on the page.`);
    }
};

/**
 * Assemble the full selector from the given data.
 * targetIdAttr and targetIdVal may be strings or arrays.
 * - If specified as strings: single attribute must match.
 * - If specified as arrays: multiple attributes must match.
 */
const getFullSelector = (selector, targetTag, targetIdAttr, targetIdVal) => {
    if (!Array.isArray(targetIdAttr)) {
        targetIdAttr = [targetIdAttr];
    }

    if (!Array.isArray(targetIdVal)) {
        targetIdVal = [targetIdVal];
    }

    const attrString = targetIdAttr.map((attr, index) => {
        return `[${attr}="${targetIdVal[index]}"]`;
    }).join('');

    return `${selector} ${targetTag}${attrString}`;
};

// Superimposes a full-window layer with a loading spinner
const showCoverLayer = () => {
    let $coverLayer = $('#coverLayer');
    if (!$coverLayer.length) {
        const spinnerHtml =
            '<div class="form_spinner" style="display: block; height: 100px;">' +
            '<i class="fas fa-spinner fa-spin fa-1x fa-fw"></i>' +
            '<span class="sr-only">Loading...</span>' +
            '</div>';

        $coverLayer = $(`
            <div id="coverLayer" class="dl-cover-layer">
                <div class="modal-dialog modal-sm" style="display:block;z-index: 10000; border: 1px solid #DDD; background-color: white;">
                    <div class="modal-content" style="box-shadow:none">
                        <div class="modal-header" style="border-bottom: 1px solid #DDD;">
                            <h4 class="modal-title" style="box-shadow:none">Loading...</h4>
                        </div>
                    </div>
                    <div class="modal-body">
                        ${spinnerHtml}
                        <div class="progress"></div>
                    </div>
                </div>
            </div>`);

        $('body').append($coverLayer);

        $coverLayer.fadeIn();

        // Retrieve the current width and set it as width to avoid the box to shift left when a scrollbar appears
        const currentWidth = $coverLayer.width();
        $coverLayer.css('width', `${currentWidth}px`);
    }
};

const hideCoverLayer = () => {
    // Wait an aditional brief moment for the rendering of the last retrieved data
    setTimeout(() => {
        $('#coverLayer').fadeOut();
    }, 300);
}

// Returns a promise that resolves when the selector returns at least one element (or a given count, or more than a given count)
const waitForElement = async (fullSelector, waitForElementCount = null, waitForElementCountGreaterThan = null) => {
    return new Promise((resolve, reject) => {
        console.log(`Waiting for: $('${fullSelector}')` + (waitForElementCount !== null ? ` (${waitForElementCount} items)` : ``));

        let target = $(fullSelector);

        const timeout = 5000;
        const interval = 100;
        let timeElapsed = 0;

        const intervalHandle = setInterval(() => {
            timeElapsed += interval;

            if (timeElapsed > timeout) {
                clearInterval(intervalHandle);
                reject('Request timed out');
            }

            target = $(fullSelector);

            if (waitForElementCount === null && waitForElementCountGreaterThan === null, target.length) {
                console.log(`Found after ${timeElapsed} ms`);
                clearInterval(intervalHandle);
                resolve(target);
            }

            if (waitForElementCount !== null && target.length === waitForElementCount) {
                console.log(`Found element count ${waitForElementCount} after ${timeElapsed} ms`);
                clearInterval(intervalHandle);
                resolve();
            }

            if (waitForElementCountGreaterThan && target.length > waitForElementCountGreaterThan) {
                console.log(`Found element count > ${waitForElementCountGreaterThan} after ${timeElapsed} ms`);
                clearInterval(intervalHandle);
                resolve();
            }

        }, interval);
    });
}

// Returns a promise that resolves when the table content has changed (based on data-id on the first row)
const waitForTableReload = async (fullSelector) => {
    const getFirstRowDataId = (rowsSelector) => {
        $rows = $(rowsSelector);

        if ($rows.length > 1) {
            // The first row has index 1 (0 is the header row)
            return $rows.eq(1).attr('data-id');
        } else {
            return null;
        }
    }

    return new Promise (async (resolve, reject) => {
        console.log('Waiting for table reload', fullSelector);
        const rowsSelector = `${fullSelector} tr`;
        let rowsPresent = $(rowsSelector).length > 1;

        const firstRowOldId = getFirstRowDataId(rowsSelector);

        let firstRowNewId;

        const timeout = 5000;
        const interval = 100;
        let timeElapsed = 0;

        const intervalHandle = setInterval(() => {
            timeElapsed += interval;

            if (timeElapsed > timeout) {
                clearInterval(intervalHandle);
                reject('Request timed out');
            }

            firstRowNewId = getFirstRowDataId(rowsSelector);

            if (firstRowNewId && (firstRowOldId !== firstRowNewId)) {
                clearInterval(intervalHandle);
                resolve();
            }
        }, 100);

    });
};


window.navigateToContext = navigateToContext;
